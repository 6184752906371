






















































































































































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import Overlay from '@/components/Overlay.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import { Model, StepTwoInterface } from '@/store/modules/room.module'
import { namespace } from 'vuex-class'
import { required, email } from 'vuelidate/lib/validators'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'

import vueCustomScrollbar from 'vue-custom-scrollbar'

const RoomStore = namespace('RoomStore')

const validations = {
  calendarId: { email },
  apiToken: { required },
  login: { required },
  password: { required },
}

@Component({
  name: 'IntegrationForm',
  components: {
    Overlay,
    BaseIcon,
    BaseButton,
    FormGroup,
    Field,
    vueCustomScrollbar,
  },
  validations,
})
export default class IntegrationForm extends Vue {
  @Prop({ default: false })
  isMobile!: boolean

  currentIntegration: null | string = null

  // google fields
  id: null | number = null
  calendarId = ''

  // yandex fields
  apiToken = ''
  login = ''
  password = ''

  @RoomStore.State('model')
  model!: Model

  @Watch('model')
  onModelChanged(val: Model): void {
    this.updateModel(val.StepTwo)
  }

  update(): void {
    this.setModel({
      ...this.model,
      StepTwo: {
        ...this.model.StepTwo,
        integrations: {
          gCalendar: {
            id: this.id,
            calendarId: this.calendarId,
          },
          yClient: {
            apiToken: this.apiToken,
            login: this.login,
            password: this.password,
          },
          current: this.currentIntegration,
        },
      },
    })
  }

  public updateModel(data: StepTwoInterface): void {
    this.currentIntegration = data?.integrations.current ?? null
    this.id = data?.integrations.gCalendar?.id ?? null
    this.calendarId = data?.integrations.gCalendar?.calendarId ?? ''
    this.apiToken = data?.integrations.yClient.apiToken
    this.login = data?.integrations.yClient.login
    this.password = data?.integrations.yClient.password
  }

  public checkValidity(): boolean {
    this.$v.$touch()

    return !this.$v.$anyError
  }

  @RoomStore.Mutation('setModel')
  setModel!: (model: Model) => void

  public mounted(): void {
    this.updateModel(this.model.StepTwo)

    // it's a stupid idea and bad practice, but.. No other idea and no time.
    if (this.currentIntegration === 'gCalendar') {
      this.apiToken = 'a'
      this.login = 'a'
      this.password = 'a'
    } else {
      this.calendarId = 'a'
    }
  }

  public save(): void {
    if (this.checkValidity()) {
      // go save
      this.update()
      this.$emit('close')
    }
  }
}
